@tailwind base;
@tailwind components;
@tailwind utilities;


html {
  background-color: black;
  scroll-behavior: smooth;
  user-select: none; 
  -webkit-user-select: none;
  -moz-user-select: none; 
  -ms-user-select: none;
}
::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.buttons {
  padding: 10px 24px;
  background-image: linear-gradient(to bottom, #a4a4ad, #f3f3f5);
  display: flex;
  text-transform: uppercase;
  align-items: center;
  gap: 8px;
  color: rgb(255, 255, 255);
  border-width: 1px;
  font-weight: 600;
  border-radius: 0.375rem;
  border-top-color: white/75;
  border-left-color: white/50;
  border-right-color: white/50;
  border-bottom-width: 0px;
  transition-duration: 100ms;
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.buttons:hover {
  background-image: linear-gradient(to bottom, #f3f3f5, #fff);
  color: black;
  border-color: white;
  --tw-shadow-color: white/10;
  --tw-shadow: var(--tw-shadow-colored);
}
.buttons2{
  font-weight:600 ;
}
@media (min-width:1440px) {
  .buttons2:hover{
    color: black;
  }
  }
#magic-cursor {
  position: absolute;
  width: 30px;
  height: 30px;
  pointer-events: none;
  z-index: 10;
}

#ball {
  position: fixed;
  display: block;
  left: 0;
  top: 0;
  transform: translate(-50%, -50%);
  width: 25px;
  height: 25px;
  border: 2px solid #00ffd9;
  border-radius: 50%;
  pointer-events: none;
  opacity: 1;
  background-color: #00ffd9;
}

.icon-wrap {
  width: 60px;
  height: 60px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.button-icon {
  height: 30px;
  width: 30px;
  color: #fff;
  text-align: center;
  line-height: 30px;
  font-size: 24px;
}

.button-text {
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  line-height: 60px;
  overflow: hidden;
  width: 140px;
  color: #fff;
  text-align: left;
  cursor: pointer;
}

.button-text span {
  position: relative;
  display: inline-block;
  transition: transform 0.2s;
  width: 140px;
}

.button-text span::before {
  position: absolute;
  width: 140px;
  top: 100%;
  left: 0;
  content: attr(data-hover);
  transform: translate3d(0, 0, 0);
}

.button-wrap:hover .button-text span {
  transform: translateY(-100%);
}
@keyframes border-spin {
  100% {
    transform: rotate(-360deg);
  }
}

.card-wrapper {
  position: relative;
  overflow: hidden;
  border-radius: 1rem;
  background-color: rgba(67, 67, 67, 0.538);
}

.card-wrapper::before {
  position: absolute;
  left: -25%;
  top: -25%;
  height: 150%;
  width: 150%;
  content: "";
  background: conic-gradient(rgb(255, 255, 255) 0deg, rgb(255, 255, 255) 0deg, transparent 50deg);
  animation: border-spin 10s linear infinite;
}

.card-content {
  position: absolute;
  left: 2.5px;
  top: 2.5px;
  height: calc(100% - 5px);
  width: calc(100% - 5px);
  border-radius: 1rem;
}
.headings2 div h1 {
  font-size: 5vw;
  font-weight: 700;
  text-transform: uppercase;
  transition: color 0.6s ease, transform 0.6s ease;
  transform-origin: center;
}

.headings2 div:hover h1 {
  color: rgb(0, 0, 0); 
}

@media (min-width: 1400px) {
  
}
.headings2 img {
  position: absolute;
  height: 20vw;
  width: 25%;
  z-index: 100;
  opacity: 0;
  object-fit: cover;
  object-position: top;
  transform-origin: center;
  pointer-events: none;
  visibility: hidden;
  transform: translate(-50%, -50%);
}
.headingelem {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-right: 10vw;
  margin-left: 3vw;
  padding: 2vw 3vw;
  opacity: 1;
  align-items: center;
  padding-right: 0px;
  -webkit-transition: color 0.3s; 
  transition: color 0.3s; 
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
}

.headingelem:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #00ffd9;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition: transform 0.6s;
  transition: transform 0.6s;
}

.headingelem:hover:before {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}
.bentobox {
  padding: 0.7rem;
  max-width: 100%;
  font-size: 1rem;
  border-width: 0.5px;
  border-style: solid;
  border-color: rgba(161, 161, 161, 0.353);
  background: linear-gradient(45deg, #232323 0%, #232323 100%);
  height: auto;
  border-radius: 16px;
  opacity: 1;
  display: inline-block;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
@media (min-width: 1400px) {
  .bentobox{
    padding: 2.5rem;
  }
}
.bentobox h1 {
  font-size: xx-large;
  text-transform: uppercase;
  font-weight: 600;
}
.bentobox:hover {
  background: linear-gradient(45deg, #3f3072 0%, #00ffd9 50%, #2f2748 100%);
  color: white;
}

.screen {
  transition: transform 0.7s;
  margin-left: 1.25rem; 
  margin-right: 1.25rem; 
  height: 40vh;
  width: 90vw;
  overflow: hidden;
  position: relative;
  border-radius: 1rem; 
  box-shadow: 30px 20px 35px 30px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
@media (min-width: 1400px) {
  .screen {
    margin-top: -4.5rem;
    height: 70vh;
  width: 90vw;
    perspective: 500px;
    transform: perspective(1000px) rotateX(10deg) rotateY(19deg) rotateZ(-5deg);
  }
}

.screen:hover {
  transform: perspective(1000px) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
}

  .screen img {
  border-radius: 1rem;
	width: screen;
	height: auto;
	position: absolute;
	z-index: 0;
  margin:0;
  padding:0;
}
@media (min-width: 1400px) {
  .screen img {
  border-radius: 1rem;
	width: 100%;
	height: auto;
	position: absolute;
	z-index: 0;
  margin:0;
  padding:0;
  -webkit-transition: top 15s;
	-moz-transition: top 15s;
	-ms-transition: top 15s;
	-o-transition: top 15s;
	transition: bottom 15s;
}
}

@media (min-width: 1400px) {
.screen:hover img {
  bottom: 0;
  -webkit-transition: all 15s;
  -moz-transition: all 15s;
  -ms-transition: all 15s;
  -o-transition: all 15s;
  transition: all 15s;
}
}
.error-container {
  text-align: center;
  font-size: 106px;
  font-weight: 800;
  margin: 70px 15px;
}
.error-container > span {
  display: inline-block;
  position: relative;
}
.error-container > span.four {
  width: 136px;
  height: 43px;
  border-radius: 999px;
  background:
    linear-gradient(140deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.07) 43%, transparent 44%, transparent 100%),
    linear-gradient(105deg, transparent 0%, transparent 40%, rgba(0, 0, 0, 0.06) 41%, rgba(0, 0, 0, 0.07) 76%, transparent 77%, transparent 100%),
    linear-gradient(to right, #00ffd9, #00bbff);
}
.error-container > span.four:before,
.error-container > span.four:after {
  content: '';
  display: block;
  position: absolute;
  border-radius: 999px;
}
.error-container > span.four:before {
  width: 43px;
  height: 156px;
  left: 60px;
  bottom: -43px;
  background:
    linear-gradient(128deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.07) 40%, transparent 41%, transparent 100%),
    linear-gradient(116deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.07) 50%, transparent 51%, transparent 100%),
    linear-gradient(to top, #00ffd9, #00fff2, #00b3ff, #00bbff, #0073ff);
}
.error-container > span.four:after {
  width: 137px;
  height: 43px;
  transform: rotate(-49.5deg);
  left: -18px;
  bottom: 36px;
  background: linear-gradient(to right, #00ffd9, #00fff2, #00b3ff, #00bbff, #0073ff);
}

.error-container > span.zero {
  vertical-align: text-top;
  width: 156px;
  height: 156px;
  border-radius: 999px;
  background: linear-gradient(-45deg, transparent 0%, rgba(0, 0, 0, 0.06) 50%,  transparent 51%, transparent 100%),
  linear-gradient(to top right, #00ffd9, #00f7ff, #00fff2, #00b3ff, #00bbff, #00aaff, #0073ff);
  overflow: hidden;
  animation: bgshadow 5s infinite;
}
.error-container > span.zero:before {
  content: '';
  display: block;
  position: absolute;
  transform: rotate(45deg);
  width: 90px;
  height: 90px;
  background-color: transparent;
  left: 0px;
  bottom: 0px;
  background:
    linear-gradient(95deg, transparent 0%, transparent 8%, rgba(0, 0, 0, 0.07) 9%, transparent 50%, transparent 100%),
    linear-gradient(85deg, transparent 0%, transparent 19%, rgba(0, 0, 0, 0.05) 20%, rgba(0, 0, 0, 0.07) 91%, transparent 92%, transparent 100%);
}
.error-container > span.zero:after {
  content: '';
  display: block;
  position: absolute;
  border-radius: 999px;
  width: 70px;
  height: 70px;
  left: 43px;
  bottom: 43px;
  background: #FDFAF5;
  box-shadow: -2px 2px 2px 0px rgba(0, 0, 0, 0.1);
}

.screen-reader-text {
    position: absolute;
    top: -9999em;
    left: -9999em;
}
    
@keyframes bgshadow {
  0% {
    box-shadow: inset -160px 160px 0px 5px rgba(0, 0, 0, 0.4);
  }
  45% {
    box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
  }
  55% {
    box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
  }
  100% {
    box-shadow: inset 160px -160px 0px 5px rgba(0, 0, 0, 0.4);
  }
}

.link-container {
  text-align: center;
}
.more-link {
  text-transform: uppercase;
  font-size: 13px;
    background-color: #00ffd9;
    padding: 10px 15px;
    border-radius: 0;
    color: #000000;
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
    line-height: 1.5;
    text-decoration: none;
  margin-top: 50px;
  letter-spacing: 1px;
}